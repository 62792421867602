.sectionTwoPublications {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  margin-top: 6%;
}

.form-control{
  width: 96% !important;
  margin-left: 2%;
}


.searchIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
}


.publish-page{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 9rem;
}

.PrivacyPolicy, .disclaimer, .termsandconditions{
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%);
  width: 70%;
}

/* 
.publish-page Footer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 20rem;
  width: 100%;
} */

/* iframe {
  width: calc(100% + 16px);
  margin: 0;
  padding: 0;
  border: none;
} */


/* For Desktop View */
@media screen and (min-width: 1024px) {
  .sectionTwoPublications {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 8%;
  }

  .searchIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .paper-content {
    width: 80%;
    margin: 9rem auto;
  }

  .link a{
    text-decoration: none !important;
    padding: 12px 15px !important;
  }

}

@media screen and (min-width: 720px) {
  .sectionTwoPublications {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 8%;
  }

  .searchIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .paper-content { 
    width: 80%;
    margin: 9rem auto;
  }

  .link a{
    text-decoration: none !important;
    padding: 12px 15px !important;
  }

}

.paperStatus{
  display: flex;
  justify-content: center;
  margin-top: 8rem;
  width: 100%;
  padding-bottom: 36vh;
}

.paperStatus h1{
  font-weight: 500;
  color: #333;
  margin-right: 1rem;
  padding-left: 1rem;
  padding: 1rem;
  font-size: 2rem;

}




/* For Tablet View */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .sectionTwoPublications {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 15%;
  }

  .searchIcon {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  .paper-content {
    width: 90%;
    margin: 9rem auto;
  }

  .link a{
    text-decoration: none !important;
    padding: 12px 15px !important;
  }

}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .sectionTwoPublications {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 40%;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .paper-content {
    width: 95%;
    margin: 9rem auto;
  }

  .link a{
    text-decoration: none !important;
    padding: 12px 15px !important;
  }

}


@media screen and (max-device-width: 560px) and (orientation: portrait) {
  .sectionTwoPublications {
    display: flex;
    flex-wrap: wrap;
    width: 95%;
    position: relative;
    left: 50%;
    transform: translate(-50%);
    margin-top: 25%;
  }

  .searchIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .paper-content {
    width: 95%;
    margin: 9rem auto;
  }

  .link a{
    text-decoration: none !important;
    padding: 12px 15px !important;
  }

}